import React, { Component } from 'react';
import Carousel from '@newfrontdoor/carousel';



class AlbumPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albumObject: this.props.albumObject,
      id: this.props.id,
      albumExists: false,
      slides: null
    }

  }


  componentWillMount() {
    if (this.state.albumObject[this.state.id]) {
      this.setState({ albumExists: true })
      this.setState({ slides: this.state.albumObject[this.state.id].images })
    }
    else {
      this.setState({ albumExists: false })
    }
  }

  render() {
    return (
      <section>
        <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
          <div className="container">
            <div className="row">
              <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
                <div id="page-title-block" className="page-title block">
                  <h1>{this.state.albumExists ? this.state.albumObject[this.state.id].title : ''}</h1>
                </div>
              </div>

              <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
                <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                  <div className="breadcrumbs">
                    <a href="/">Home</a>
                    <span className="delimiter">›</span>
                    <a href="/gallery" title="">Gallery</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="content-region">
          <div className="container">
            <div className="row">
              <div id="main-content-region" className="main-content col-xs-12">
                <div className="region region-content">
                  <div id="block-system-main" className="block block-system">
                    <div className="content">
                      <div className="node node-page clearfix">
                        <div className="content">
                          {this.state.slides && this.state.albumExists && (
                            <Carousel style={{ height: '400px' }}>
                              {this.state.slides.map(slide => (
                                <img style={{ width: "100%" }} key={slide} src={slide} alt={''} />
                              ))}
                            </Carousel>)}
                          {this.state.albumExists === false && (
                            <p>Album does not exist</p>
                          )}
                        </div>



                      </div>
                    </div>
                  </div>  </div>



              </div>




            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AlbumPage;
