import React, { useEffect, useState } from 'react';
import { getAlbums } from '../../utils/fetchJSON';
import Album from '../models/Album'

export default function Gallery({ gallery, albumObj }) {
  const [data, setData] = useState(gallery);
  const [albumObject, setAlbumObject] = useState(albumObj) //eslint-disable-line
  const [dataFetched, setDataFetched] = useState(Boolean(albumObj));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (dataFetched === false) {
      if (gallery) {

        setData(gallery);
        setAlbumObject(albumObj)
        setDataFetched(true);
        setLoading(false)
      }
      else {
        getAlbums(function (data) {
          setData(data);
          setLoading(false)
          setDataFetched(true);
        });
      }
      console.log(data)
    }
  }, [dataFetched, data, gallery, loading, setLoading, albumObj]);

  var albums = <h2><i className="fa fa-spinner"></i></h2>;
  if (data !== undefined) {
    console.log(data)
    albums = data.map(album => <Album key={album.id} id={album.id} name={album.title} image={album.thumb} startDate={album.startdate} endDate={album.enddate} />)
  }

  return (
    <section>
      <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
        <div className="container">
          <div className="row">
            <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
              <div id="page-title-block" className="page-title block">
                <h1>Gallery</h1>
              </div>
            </div>

            <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
              <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                <div className="breadcrumbs">
                  <a href="/">Home</a>
                  <span className="delimiter">›</span>
                  <span title="" className="nolink">Gallery</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="content-region">
        <div className="container">
          <div className="row">
            <div id="main-content-region" className="main-content col-xs-12">
              <div className="region region-content">
                <div className="content">
                  {albums}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}